<template>
  <div v-if="checkUser">
    <b-row class="mt-5">
      <b-col cols="3" v-for="(metric, ix) in paymentMetrics" :key="ix">
        <rm-metric-card :metric="metric" />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <payment-table></payment-table>
      </b-col>
    </b-row>
    <!-- <div class="d-flex">
			<rm-new-clients-chart />
			<rm-country-chart class="mx-4" />
			<rm-age-chart />
		</div>
		<b-row class="mt-5">
			<b-col cols="12">
				<rm-clients-table />
			</b-col>
			<b-col cols="4"> </b-col>
			<b-col cols="4"> </b-col>
		</b-row> -->
  </div>
  <div v-else>
    <p>You do not have access to this content</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import RmInsights from "./components/RmInsights.vue";
// import RmPlotSales from "./components/RmPlotSales.vue";
// import RmRatingCard from "./components/RmRatingsCard.vue";
import PaymentTable from './components/PaymentTable.vue';
export default {
  components: {
    // RmInsights,
    // RmPlotSales,
    // RmRatingCard,
    PaymentTable,
  },
  data: () => ({
    metricOptions: ['All Time'],
  }),
  computed: {
    ...mapGetters({
      count: 'payments/count',
      pendingCount: 'payments/pendingCount'
    }),
    ...mapState('auth', ['userObj']),
    userRoles() {
      return  this.userObj.role.scopes ?? []
    },
    checkUser(){
      if (this.userRoles.includes('payment.read')){
        return true
      } else {
        return false
      }
    },
    paymentMetrics() {
      return [
        {
          name: 'All Payments',
          amount: this.count,
          growth: +100,
          icon: 'payment-icon',
        },
        // {
        //   name: "Payments Default",
        //   amount: "3000",
        //   growth: +15,
        //   icon: "active-clients",
        // },
        {
          name: 'Unapproved Payments',
          amount: this.pendingCount,
          growth: +100,
          icon: 'pending-payment',
          route: 'payments.pending',
        },
        // {
        //   name: "Pending Withdrawal",
        //   amount: "1000",
        //   growth: -22.5,
        //   icon: "prospective-clients",
        // },
      
      ]
    },
  },
  methods: {},
  async created() {
    if(this.checkUser){
      const loader = this.$loading.show();
      try {
        await this.$store.dispatch('payments/fetchPaymentCount');
        await this.$store.dispatch('payments/fetchPendingPaymentCount');
      } catch (error) {
        console.log(error)
      } finally {
        loader.hide();
      }
    }
  },
};
</script>
