<template>
  <div>
    <div class="h4 text-right mb-4">
      <b-icon
        class="pointer text-right"
        icon="x-circle-fill"
        @click="close()"
      ></b-icon>
    </div>
    <div class="title">
      <h4>
        {{ payment.user.first_name }} {{ payment.user.last_name }} / ₦
        {{ $formatCurrency(payment.amount / 100) }}
      </h4>
      <!-- <span class="pointer">Print<rm-base-icon name="print" /></span> -->
    </div>

    <b-row class="mt-3">
      <b-col class="d-flex justify-content-between mt-3" cols="12">
        <div class="name">Client Name</div>
        <div class="value">
          {{ payment.user.first_name }} {{ payment.user.last_name }}
        </div>
      </b-col>
      <b-col class="d-flex justify-content-between mt-3" cols="12">
        <div class="name">Payment Method</div>
        <div class="value">{{ payment.payment_method }}</div>
      </b-col>
      <b-col class="d-flex justify-content-between mt-3" cols="12">
        <div class="name">Payment date</div>
        <div class="value">{{ payment.created_at | luxon }}</div>
      </b-col>
      <b-col class="d-flex justify-content-between mt-3" cols="12">
        <div class="name">Description</div>
        <div class="value text-right">{{ payment.description }}</div>
      </b-col>
      <b-col class="d-flex justify-content-between mt-3" cols="12">
        <div class="name">Payment Status</div>
        <div class="value text-capitalize">{{ payment.status }}</div>
      </b-col>
      <b-col class="mt-5" cols="12">
        <rm-input
          v-if="payment.status === 'pending'"
          v-model="form.amount_paid"
          outlined
          class="bg-white"
          placeholder="Please Enter Amount Paid"
        ></rm-input>
      </b-col>
    </b-row>

    <b-row align-h="end" class="mt-5">
      <b-col cols="4" class="mb-4">
        <rm-base-button
          @click="declinePayment"
          class="ml-auto"
          text="Decline Payment"
          bgColor="gray-700"
          textColor="black"
        ></rm-base-button>
      </b-col>
      <b-col cols="4" class="mb-4">
        <rm-base-button
          @click="updatePayment"
          class="ml-auto"
          :text="
            payment.status === 'verified' ? 'Approve Payment' : 'Verify Amount'
          "
          bgColor="primary"
          textColor="white"
        ></rm-base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    id: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      form: {
        amount_paid: '',
        payment_id: '',
      },
    };
  },
  validations: {},
  computed: {
    ...mapGetters({
      payment: "payments/payment"
    })
  },
  created () {
    this.form = {
      amount_paid: `${this.payment.amount / 100}`,
      payment_id: this.payment.id
    }
  },
  methods: {
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));

      this.$bvModal.hide('modal-lg-payment-modal');
    },
    async declinePayment() {
      const loader = this.$loading.show();
      try {
        await this.$store.dispatch(
          "payments/declinePayment",
          { ...this.form }
        );
      } catch (error) {
        console.log(error)
        this.$store.dispatch('toast', {
          variant: 'error',
          message: 'Error Occured. Failed to decline payment',
        });
      } finally {
        this.$emit('completed');
        this.close();
        loader.hide();
      }
    },
    async updatePayment() {
      const loader = this.$loading.show();
      const status = this.payment.status;
      try {
        if (status === 'pending') {
          await this.verifyPayment();
        } else if (status === 'verified') {
          await this.approvePayment();
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('toast', {
          variant: 'error',
          message: `Error Occured. Failed to ${status === 'pending' ? 'Verify Amount' : 'Approve Payment'}`,
        });
      } finally {
        this.$emit('completed');
        this.close();
        loader.hide();
      }
    },
    async verifyPayment() {
      await this.$store.dispatch(
        "payments/verifyPayment",
        {
          ...this.form,
          amount_paid: Number(this.form.amount_paid * 100),
        }
      );
    },
    async approvePayment() {
      await this.$store.dispatch(
        "payments/approvePayment",
        { ...this.form }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;

  span {
    font-size: 19px;
    color: #007aa9;
  }
}

.pointer {
  cursor: pointer;
}

.name {
  color: #8a8b8a;
}

.line {
  border: 1px solid #d0d1d0;
}

.form-error {
  margin-top: -1rem;
}

.big-center-text {
  font-size: 1.6rem;
  // font-weight: 600;
  text-align: center;
}

#tags-basic {
  color: white;
  background-color: blue;
}
</style>
